import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';

import useStyles from './style';

const ArticelCard = props => {
  const classes = useStyles(props);
  const { className, article } = props;
  const history = useHistory();

  const openArticle = event => {
    event.stopPropagation();
    history.push(`/cikk/${article.id}`);
  };

  const renderTitle = title => (
    <div className={classes.titleContainer} onClick={event => openArticle(event)}>
      <Typography className={classes.title} variant="h3" color="primary">
        {title}
      </Typography>
    </div>
  );

  return (
    <Card className={`${classes.container} ${className}`}>
      <CardMedia
        className={classes.media}
        image={article.img.src}
        onClick={event => openArticle(event)}
      >
        {props.size === 'simple' && renderTitle(article.title)}
      </CardMedia>
      <CardContent className={classes.content}>
        {props.size === 'double' && renderTitle(article.title)}
        <div className={classes.textContainer}>
          <Typography variant="body2">{article.lead}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const sizes = ['simple', 'double'];

ArticelCard.propTypes = {
  size: PropTypes.oneOf(sizes),
};

ArticelCard.defaultProps = {
  className: '',
  size: 'simple',
};

export default ArticelCard;
