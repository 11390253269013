import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    cursor: 'pointer',
    marginTop: theme.spacing(-4),
    marginLeft: theme.spacing(-4),
    '& svg path': {
      fill: ({ type }) => (type === 'link' ? theme.palette.link : theme.palette.background.active),
    },
  },
}));

export default useStyles;
