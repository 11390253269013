import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import ArticleCard from '../ArticleCard';

import useStyles from './style';

const ArticleContainer = ({ articles, alternate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className={classes.container}>
      {articles.map((article, index) => {
        const double = (index + 1) % 4 === 0 || (index + 1) % 4 === 1;
        const size = alternate ? (matches && double ? 'double' : 'simple') : 'simple';

        return (
          <ArticleCard
            className={alternate && classes.alternate}
            key={article.id}
            article={article}
            size={size}
          />
        );
      })}
    </div>
  );
};

export default ArticleContainer;
