import { Fragment } from 'react';

import Slider from 'react-slick';

import Dialog from '../Dialog';

import useStyles from './style';

const Carousel = ({ slides, dialog, imageClass }) => {
  const classes = useStyles();

  const settings = {
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
  };

  return (
    <div className={classes.container}>
      <Slider className={classes.slider} {...settings}>
        {slides.map(slide => (
          <Fragment key={slide.id}>
            {/* <div
              className={[classes.image, imageClass].filter(Boolean).join(' ')}
              style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url('${slide.src}')`,
              }}
            ></div> */}
            <img
              className={[classes.image, imageClass].filter(Boolean).join(' ')}
              src={slide.src}
              alt={slide.src}
            />
          </Fragment>
        ))}
      </Slider>
      {dialog && (
        <Dialog
          className={classes.modal}
          text="Járd be virtuálisan a fejlesztési területet és nézd meg mi változik!"
        />
      )}
    </div>
  );
};

export default Carousel;
