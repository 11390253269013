import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - (62px + 353px))',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - (62px + 261px))',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - (74px + 261px))',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 'calc(100vh - (74px + 146px))',
    },
  },
  page: {
    padding: theme.spacing(4, 0),
  },
}));

export default useStyles;
