import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(theme => ({
  container: ({ size }) => ({
    minHeight: '25rem',
    maxWidth: size === 'double' ? '46rem' : '22rem',
    position: 'relative',
    display: 'flex',
    flexDirection: size === 'double' ? 'row' : 'column',
  }),
  media: ({ size }) => ({
    position: 'relative',
    width: size === 'double' ? `calc(50% - ${theme.spacing(4)})` : '100%',
    height: size === 'double' ? '100%' : '12.5rem',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  }),
  content: ({ size }) => ({
    width: size === 'double' ? `calc(50% + ${theme.spacing(4)}))` : 'auto',
    display: 'inline-flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(6, 8),
  }),
  titleContainer: ({ size }) => ({
    position: size === 'double' ? 'relative' : 'absolute',
    bottom: 0,
    margin: size === 'double' ? theme.spacing(0, 0, 4, 0) : theme.spacing(8, 8, 4, 8),
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    '&:hover h3': {
      backgroundColor: darken(theme.palette.background.active, 0.05),
      boxShadow: `
        ${theme.spacing(-1)} 0 0 ${darken(theme.palette.background.active, 0.05)},
        ${theme.spacing(3)} 0 0 ${darken(theme.palette.background.active, 0.05)}
      `,
    },
  }),
  title: {
    display: 'inline',
    lineHeight: 1.5,
    backgroundColor: theme.palette.background.active,
    boxShadow: `
      ${theme.spacing(-1)} 0 0 ${theme.palette.background.active},
      ${theme.spacing(3)} 0 0 ${theme.palette.background.active}
    `,
  },
  textContainer: {
    flex: 1,
  },
}));

export default useStyles;
