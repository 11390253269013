import {
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import ScrollToTop from 'react-scroll-up';

import MediaViewer from '../../MediaViewer';

import useStyles from './style';

const Article = ({ article }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const scrollToTopStyle = {
    bottom: matches ? '2.5rem' : '1.5rem',
    right: matches ? '2.5rem' : '1.5rem',
    WebkitTapHighlightColor: 'transparent',
  };

  const renderContent = content => {
    switch (content.type) {
      case 'text':
        return renderTextContent(content.items);
      case 'image':
        return renderImageContent(content.items);
      case 'youtube':
        return renderYouTubeContent(content.items);
      case 'accordion':
        return renderAccordionContent(content.items);
      default:
        return null;
    }
  };

  const renderTextContent = items =>
    items.map((item, index) => (
      <Typography
        className={classes.textContent}
        key={index}
        variant="body2"
        dangerouslySetInnerHTML={{ __html: item }}
      />
    ));

  const renderImageContent = items =>
    items.map((item, index) => (
      <div
        key={index}
        className={classes.imageContent}
        style={item.align && { justifyContent: item.align }}
      >
        {item.caption ? (
          <figure className={classes.figure}>
            <img alt={item.src} src={item.src} />
            <figcaption className={classes.figcaption}>
              <Typography variant="caption" dangerouslySetInnerHTML={{ __html: item.caption }} />
            </figcaption>
          </figure>
        ) : (
          <img alt={item.src} src={item.src} />
        )}
      </div>
    ));

  const renderYouTubeContent = items =>
    items.map((item, index) => (
      <div key={index} className={classes.imageContent}>
        <MediaViewer
          className={classes.youtubeContent}
          type="video"
          aspectRatioXs="16:9"
          aspectRatioSm="16:9"
          src={item}
        />
      </div>
    ));

  const renderAccordionDetail = detail => {
    switch (detail.type) {
      case 'text':
        return renderTextContent(detail.items);
      case 'image':
        return renderImageContent(detail.items);
      default:
        return null;
    }
  };

  const renderAccordionContent = items =>
    items.map((item, itemIndex) => (
      <Accordion key={itemIndex} square elevation={0} expanded={item.open}>
        <AccordionSummary expandIcon={<ArrowForwardIcon className={classes.accordionExpand} />}>
          <Typography variant="body2" color="primary">
            {item.summary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {item.details.map(detail => renderAccordionDetail(detail))}
        </AccordionDetails>
      </Accordion>
    ));

  return (
    <>
      {article.img && (
        <div className={classes.mainImage}>
          {article.img.caption ? (
            <figure className={classes.figure}>
              <img alt={article.img.src} src={article.img.src} />
              <figcaption className={classes.figcaption}>
                <Typography variant="caption">{article.img.caption}</Typography>
              </figcaption>
            </figure>
          ) : (
            <img alt={article.img.src} src={article.img.src} />
          )}
        </div>
      )}

      <div className={classes.container}>
        <Typography className={classes.mainTitle} variant="h2" color="primary">
          {article.titleLong || article.title}
        </Typography>

        {article.sections &&
          article.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className={classes.section}>
              {section.title && (
                <Typography className={classes.sectionTitle} variant="h3" color="primary">
                  {section.title}
                </Typography>
              )}
              {section.contents &&
                section.contents.map((content, contentIndex) => (
                  <div key={contentIndex} className={classes.sectionContent}>
                    {renderContent(content)}
                  </div>
                ))}
            </div>
          ))}
      </div>

      <ScrollToTop showUnder={700} style={scrollToTopStyle}>
        <div className={classes.backToTopContainer}>
          <ArrowUpwardIcon className={classes.backToTopIcon} />
        </div>
      </ScrollToTop>
    </>
  );
};

export default Article;
