import { Container, Typography } from '@material-ui/core';

import useStyles from './style';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container className={classes.container} maxWidth="lg" disableGutters>
        <a href="https://www.szegedvaros.hu/kezdolap/" target="_blank" rel="noopener noreferrer">
          <div className={classes.client}>
            <div className={classes.clientLogo}>
              <img
                alt="Megújul az Oskola utca, a Hídfő és a Stefánia"
                src="/images/szeged_logo.png"
              />
            </div>
            <div>
              <div className={classes.row}>
                <div>
                  <Typography variant="caption" color="secondary">
                    Szeged Megyei Jogú Város Önkormányzata
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" color="secondary">
                    6720 Szeged, Széchenyi tér 10-11.
                  </Typography>
                </div>
              </div>
              {/* <div>
                <Typography variant="caption" color="secondary">
                  Kapcsolat:
                  <br />
                  megujul@szegedpolus.hu
                </Typography>
              </div> */}
            </div>
          </div>
        </a>
        <div className={classes.contributors}>
          <div className={classes.constructors}>
            <div className={classes.row}>
              <Typography variant="caption" color="secondary">
                A kivitelezést koordinálja:
                <br />
                <a href="http://szegedpolus.hu/" target="_blank" rel="noopener noreferrer">
                  Szeged Pólus Fejlesztési Nonprofit Kft.
                </a>
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color="secondary">
                Kivitelező:
                <br />
                <a href="http://www.delut.hu/" target="_blank" rel="noopener noreferrer">
                  Délút Építő és Bányászati Kft.
                </a>
              </Typography>
            </div>
          </div>
          <div>
            <div className={classes.row}>
              <Typography variant="caption" color="secondary">
                Kommunikáció és koncepció:
                <br />
                <a
                  href="https://www.elephantintheroom.hu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Elephant in the Room
                </a>
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="caption" color="secondary">
                3D: Filus Norbert
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color="secondary">
                Webfejlesztés:
                <br />
                Deák Gábor
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.partners}>
          <div className={classes.partnerLogos}>
            <a href="https://uia-initiative.eu/en" target="_blank" rel="noopener noreferrer">
              <img alt="Urban Innovative Actions: UIA" src="/images/footer/uia_logo.png" />
            </a>
            <a
              className={classes.middle}
              href="http://sasmob-szeged.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="SASMob - Szeged" src="/images/footer/sasmob_logo.png" />
            </a>
            <img
              alt="European Union - European Regional Development Fund"
              src="/images/footer/eu.png"
            />
          </div>
          <div className={classes.copyright}>
            <Typography variant="caption" color="secondary">
              2021. Minden jog fenntartva
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
