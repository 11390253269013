import { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../router';

import DataContext from '../../context';

import Page from '../Page';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';

const PortalContainer = () => {
  const { portalData } = useContext(DataContext);

  return portalData ? (
    <>
      <Header />
      <Page>
        <Switch>
          {routes
            .filter(route => route.component)
            .map(({ path, component }, key) => (
              <Route exact path={path} component={component} key={key} />
            ))}
        </Switch>
      </Page>
      <Footer />
    </>
  ) : (
    <Loading />
  );
};

export default PortalContainer;
