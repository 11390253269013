import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import theme from './theme';

import { DataProvider } from '../../context';

import VirtualTourContainer from '../VirtualTourContainer';
import PortalContainer from '../PortalContainer';

import Cookie from '../../components/Cookie';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <Switch>
          <DataProvider>
            <Switch>
              <Route path="/virtualis-tura" component={VirtualTourContainer} />
              <Route path="/" component={PortalContainer} />
              <Redirect to="/" />
            </Switch>
          </DataProvider>
        </Switch>
      </BrowserRouter>

      <Cookie />
    </ThemeProvider>
  );
};

export default App;
