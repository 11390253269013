import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Marzipano from 'marzipano';

import DataContext from '../../../context';

import Modal from '../../Modal';
import Scene from '../Scene';
import MenuIcon from '../../MenuIcon';
import Drawer from '../../Drawer';

import useStyles from './style';

const Viewer = () => {
  const classes = useStyles();
  let history = useHistory();
  let { sceneId } = useParams();

  const { virtualTours } = useContext(DataContext);

  const viewerRef = useRef();
  const [viewer, setViewer] = useState(null);
  const [scenes, setScenes] = useState();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const changeScene = useCallback(
    (targetSceneId, init = false) => {
      const sceneIndex = scenes.findIndex(scene => scene.id === targetSceneId);
      if (sceneIndex !== -1) {
        viewer.listScenes()[sceneIndex].switchTo();
        if (!init) {
          window.history.replaceState(null, targetSceneId, `/virtualis-tura/${targetSceneId}`);
        }
      } else {
        history.replace('/');
      }
    },
    [scenes, viewer, history]
  );

  const handleDrawerOpen = () => {
    if (!drawerOpen) {
      setDrawerOpen(true);
    }
  };

  useEffect(() => {
    setScenes(virtualTours);
    setViewer(new Marzipano.Viewer(viewerRef.current, { controls: { mouseViewMode: 'drag' } }));
    return () => {
      setViewer(null);
    };
  }, [virtualTours]);

  useEffect(() => {
    if (viewer) {
      changeScene(sceneId, true);
    }
  }, [viewer, sceneId, changeScene]);

  const handleHotspotClick = (sceneId, index) => {
    const hotspot = scenes.find(scene => scene.id === sceneId).hotspots[index];
    switch (hotspot.type) {
      case 'info':
        handleModalOpen(hotspot);
        break;
      case 'link':
        handleSceneChange(hotspot);
        break;
      default:
        return;
    }
  };

  const handleModalOpen = hotspot => {
    setModalContent({ title: hotspot.title, text: hotspot.text });
    setModalOpen(true);
  };

  const handleSceneChange = hotspot => {
    changeScene(hotspot.target);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className={classes.container}>
      <Modal open={modalOpen} handleClose={handleModalClose} content={modalContent} />

      <div className={classes.menuButton} onClick={handleDrawerOpen}>
        <MenuIcon />
      </div>

      <Drawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        title={['virtuális túra']}
        menu={virtualTours
          .filter(scene => !scene.demo)
          .map(scene => {
            return { name: scene.title, path: `/virtualis-tura/${scene.id}` };
          })}
        back={{ path: '/', text: 'kilépés a túrából' }}
      />

      <a href="https://www.szegedvaros.hu/kezdolap/" target="_blank" rel="noopener noreferrer">
        <div className={classes.logo}>
          <img alt="Szeged logó" src="/images/szeged_logo.png" />
        </div>
      </a>

      <div className={classes.viewer} ref={viewerRef}>
        {viewer &&
          scenes.map(scene => (
            <Scene
              key={scene.id}
              viewer={viewer}
              data={scene}
              onInfoHotspotClick={handleHotspotClick}
            />
          ))}
      </div>
    </div>
  );
};

export default Viewer;
