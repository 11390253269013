import { useContext, useLayoutEffect } from 'react';

import DataContext from '../../context';

import Carousel from '../../components/Carousel';
import ArticleContainer from '../../components/Article/ArticleContainer';

import useStyles from './style';

const LocalHistoryPage = () => {
  const classes = useStyles();

  const { portalData } = useContext(DataContext);
  const { localHistorySlides, articles } = portalData;

  const localHistoryArticles = articles.filter(article => article.type === 'helytortenet');

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Carousel slides={localHistorySlides} imageClass={classes.sliderImage} />
      <ArticleContainer articles={localHistoryArticles} />
    </>
  );
};

export default LocalHistoryPage;
