import { useContext, useLayoutEffect } from 'react';

import DataContext from '../../context';

import Carousel from '../../components/Carousel';
import ArticleContainer from '../../components/Article/ArticleContainer';

const HomePage = () => {
  const { portalData } = useContext(DataContext);
  const { homeSlides, articles } = portalData;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Carousel slides={homeSlides} dialog />
      {/* <ArticleContainer alternate articles={articles.filter(article => !article.type)} /> */}
      <ArticleContainer articles={articles.filter(article => !article.type)} />
    </>
  );
};

export default HomePage;
