import { useState } from 'react';

import { Container, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

const Cookie = () => {
  const theme = useTheme();
  const [visible, setVisible] = useState(!getCookieConsentValue());

  return (
    visible && (
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: theme.spacing(4),
          backgroundColor: theme.palette.background.primary,
          boxShadow: '0px -3px 6px rgba(0, 0, 0, 0.16)',
        }}
      >
        <Container maxWidth="lg" disableGutters style={{}}>
          <CookieConsent
            buttonText="Rendben"
            onAccept={() => setVisible(false)}
            style={{
              position: 'relative',
              alignItems: 'center',
              color: theme.palette.primary.main,
              backgroundColor: 'transparent',
            }}
            contentStyle={{
              flex: 1,
              margin: 0,
              marginRight: theme.spacing(4),
            }}
            buttonStyle={{
              minHeight: '2rem',
              margin: 0,
              padding: theme.spacing(0, 9),
              border: 0,
              borderRadius: 0,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
              backgroundColor: theme.palette.background.active,
              fontFamily: theme.fontFamily.primary,
              fontSize: '1.5rem',
              lineHeight: 1,
              fontWeight: '400',
            }}
          >
            <Typography variant="body2">
              Az oldal sütiket használ a felhasználói élmény fokozása érdekében.
            </Typography>
          </CookieConsent>
        </Container>
      </div>
    )
  );
};

export default Cookie;
