import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, max-content)',
    gridGap: theme.spacing(4),
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'max-content max-content',
      gridGap: theme.spacing(8),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  alternate: {
    [theme.breakpoints.up('lg')]: {
      '&:nth-child(4n+1)': {
        gridColumnStart: 1,
        gridColumnEnd: 3,
      },
      '&:nth-child(4n+2)': {
        gridColumnStart: 3,
        gridColumnEnd: 4,
      },
      '&:nth-child(4n+3)': {
        gridColumnStart: 1,
        gridColumnEnd: 2,
      },
      '&:nth-child(4n)': {
        gridColumnStart: 2,
        gridColumnEnd: 4,
      },
    },
  },
}));

export default useStyles;
