import PropTypes from 'prop-types';

import { useEffect, useRef } from 'react';
import { Lottie } from '@crello/react-lottie';
import animationData from './animation.json';

import useStyles from './style';

const Hotspot = props => {
  const classes = useStyles(props);
  const hotspotRef = useRef();
  const { scene, position, onHotspotClick } = props;

  useEffect(() => {
    scene.hotspotContainer().createHotspot(hotspotRef.current, position);
  }, [scene, position]);

  return (
    <div className={classes.container} ref={hotspotRef} onClick={onHotspotClick}>
      <Lottie config={{ animationData: animationData, loop: true }} />
    </div>
  );
};

const types = ['info', 'link'];

Hotspot.propTypes = {
  type: PropTypes.oneOf(types),
};

Hotspot.defaultProps = {
  type: 'info',
};

export default Hotspot;
