import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
  videoCoverContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  play: {
    display: 'none',
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    minHeight: '20rem',
    maxHeight: '30rem',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  playButton: {
    position: 'absolute',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(3),
    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
      color: theme.palette.background.default,
    },
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    //paddingBottom: '42.858%', // 21:9 (9 / (21 / 100) = 42.8571428571)
    //paddingBottom: '56.25%', // 16:9 (9 / (16 / 100) = 56.25)
    paddingBottom: ({ aspectRatioXs }) => (aspectRatioXs === '16:9' ? '56.25%' : '42.858%'),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: ({ aspectRatioSm }) => (aspectRatioSm === '16:9' ? '56.25%' : '42.858%'),
    },
    '& iframe': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  },
}));

export default useStyles;
