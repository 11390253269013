import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.primary,
  },
  viewer: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.active,
    borderRadius: theme.spacing(4),
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    '& svg': {
      width: theme.spacing(6),
      height: theme.spacing(6),
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      left: theme.spacing(8),
    },
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    display: 'flex',
    maxWidth: theme.spacing(12),
    maxHeight: theme.spacing(12),
    zIndex: 1,
    '& img': {
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(8),
      maxWidth: theme.spacing(16),
      maxHeight: theme.spacing(16),
    },
  },
}));

export default useStyles;
