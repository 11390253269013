import Container from '@material-ui/core/Container';

import useStyles from './style';

const Page = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container className={classes.page} maxWidth="lg" disableGutters>
        {children}
      </Container>
    </div>
  );
};

export default Page;
