import React from 'react';
import ReactDOM from 'react-dom';

import '@fontsource/source-sans-pro';
import '@fontsource/bebas-neue';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import App from './containers/App';

ReactDOM.render(<App />, document.getElementById('root'));
