import { useContext, useLayoutEffect } from 'react';

import DataContext from '../../context';

import MediaViewer from '../../components/MediaViewer';
import ArticleContainer from '../../components/Article/ArticleContainer';

const TransportPage = () => {
  const articles = useContext(DataContext).portalData.articles.filter(
    article => article.type === 'kozlekedes'
  );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* /images/kozlekedes/Hidfo_web_w1080.jpg */}
      <MediaViewer type="video" aspectRatioXs="16:9" aspectRatioSm="21:9" src="sbOzu4FdZXA" />
      <ArticleContainer articles={articles} />
    </>
  );
};

export default TransportPage;
