import { useHistory } from 'react-router-dom';

import { Paper, Typography, Button } from '@material-ui/core';

import useStyles from './style';

const Dialog = ({ className = '', text }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={`${classes.root} ${className}`}>
      <Typography className={classes.text} variant="body1">
        {text}
      </Typography>
      <Button color="primary" onClick={() => history.push('/virtualis-tura')}>
        kezdés
      </Button>
    </Paper>
  );
};

export default Dialog;
