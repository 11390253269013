import { useState, useEffect } from 'react';

import Marzipano from 'marzipano';

import Hotspot from '../Hotspot';

const degToRad = degrees => degrees * (Math.PI / 180);

const Scene = ({ viewer, data, onInfoHotspotClick, onLinkHotspotClick }) => {
  const [scene, setScene] = useState(null);

  useEffect(() => {
    const source = Marzipano.ImageUrlSource.fromString(
      `/data/virtualis-tura/tiles/${data.id}/{z}/{f}/{y}/{x}.jpg`,
      { cubeMapPreviewUrl: `/assets/tiles/${data.id}/preview.jpg` }
    );

    const geometry = new Marzipano.CubeGeometry([
      { tileSize: 256, size: 256, fallbackOnly: true },
      { tileSize: 512, size: 512 },
      { tileSize: 512, size: 1024 },
      { tileSize: 512, size: 2048 },
    ]);

    const maxHFov = degToRad(120);
    const maxVFov = degToRad(100);
    const maxResolution = 8192;

    const aspectRatio = window.innerWidth / window.innerHeight;
    let actualHFov = maxHFov;
    let actualVFov = maxVFov;

    if (aspectRatio * maxVFov < maxHFov) {
      actualHFov = 2.0 * Math.atan(aspectRatio * Math.tan(maxVFov * 0.5));
    } else {
      actualVFov = 2.0 * Math.atan(Math.tan(maxHFov * 0.5) / aspectRatio);
    }
    const initialView = { ...data.initialView, fov: actualVFov };
    let limiter = Marzipano.RectilinearView.limit.traditional(maxResolution, maxVFov, maxHFov);

    if (data.limit) {
      const hDiff = (maxHFov - actualHFov) / 2.0;
      const vDiff = (maxVFov - actualVFov) / 2.0;

      limiter = Marzipano.util.compose(
        Marzipano.RectilinearView.limit.traditional(maxResolution, maxVFov, maxHFov),
        Marzipano.RectilinearView.limit.yaw(
          data.limit.h?.min ? degToRad(data.limit.h.min) - hDiff : degToRad(-180),
          data.limit.h?.max ? degToRad(data.limit.h.max) + hDiff : degToRad(180)
        ),
        Marzipano.RectilinearView.limit.pitch(
          data.limit.v?.min ? degToRad(data.limit.v.min) - vDiff : degToRad(-90),
          data.limit.v?.max ? degToRad(data.limit.v.max) + vDiff : degToRad(90)
        )
      );
    }

    const view = new Marzipano.RectilinearView(initialView, limiter);

    const scene = viewer.createScene({
      source,
      geometry,
      view,
    });

    setScene(scene);
  }, [data, viewer]);

  return scene && data.hotspots
    ? data.hotspots.map(({ type, position }, index) => {
        return (
          <Hotspot
            key={index}
            scene={scene}
            position={position}
            type={type}
            onHotspotClick={() => onInfoHotspotClick(data.id, index)}
          />
        );
      })
    : null;
};

export default Scene;
