import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    paddingRight: theme.spacing(8),
    '& h1': {
      marginBottom: theme.spacing(2),
    },
    '& h1:last-child': {
      marginBottom: 0,
    },
  },
  menuItem: {
    marginBottom: theme.spacing(8),
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    textDecoration: 'none',
  },
  backIcon: {
    fill: theme.palette.primary.main,
  },
  backText: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
  },
}));

export default useStyles;
