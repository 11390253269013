import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
  slider: {
    '& .slick-slide > div': {
      position: 'relative',
    },
    '& .slick-dots': {
      bottom: theme.spacing(4),
      '& li': {
        margin: 0,
      },
      '& li button:before': {
        fontSize: '8px',
        color: theme.palette.background.default,
        opacity: 1,
      },
      '& li.slick-active button:before': {
        color: theme.palette.link,
      },
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    minHeight: '20rem',
    maxHeight: '30rem',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyles;
