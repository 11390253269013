import React, { useState, useEffect } from 'react';

import DataContext from './DataContext';

const DataProvider = ({ children }) => {
  const [portalData, setPortalData] = useState();
  const [virtualTours, setVirtualTours] = useState();

  const fetchData = async url => {
    const response = await fetch(url);
    return await response.json();
  };

  useEffect(() => {
    (async () => {
      const portalDataResponse = await fetchData('/data/portal/data_1662473386904.json');
      setPortalData(portalDataResponse);

      const virtualTourDataResponse = await fetchData(
        '/data/virtualis-tura/data_1631889736507.json'
      );
      setVirtualTours(virtualTourDataResponse);
    })();
  }, []);

  return (
    <DataContext.Provider value={{ portalData, virtualTours }}>{children}</DataContext.Provider>
  );
};

export default DataProvider;
