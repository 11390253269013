import { useHistory } from 'react-router-dom';

import { SwipeableDrawer, Typography, Divider } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowBack';

import useStyles from './style';

const Drawer = ({ open, setOpen, title, menu, back }) => {
  const classes = useStyles();

  const history = useHistory();

  const handlePageChange = path => {
    setOpen(false);
    history.push(path);
  };

  return (
    <SwipeableDrawer open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <div className={classes.title}>
        {title.map((item, index) => (
          <Typography key={index} variant="h1" color="primary">
            {item}
          </Typography>
        ))}
      </div>

      <Divider />

      {menu.map(item => (
        <Typography
          className={classes.menuItem}
          key={item.name}
          variant="button"
          color="primary"
          onClick={() => handlePageChange(item.path)}
        >
          {item.name}
        </Typography>
      ))}

      {back && (
        <div className={classes.back} onClick={() => handlePageChange(back.path)}>
          <ArrowIcon className={classes.backIcon} />
          <Typography className={classes.backText} variant="button" color="primary">
            {back.text}
          </Typography>
        </div>
      )}
    </SwipeableDrawer>
  );
};

export default Drawer;
