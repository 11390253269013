import { useContext, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import DataContext from '../../context';

import Article from '../../components/Article/Article';

const ArticlePage = () => {
  const { id } = useParams();
  const article = useContext(DataContext).portalData.articles.find(article => article.id === id);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Article article={article} />;
};

export default ArticlePage;
