import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainImage: {
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
    '& figcaption': {
      textAlign: 'right',
    },
  },
  figure: {
    margin: 0,
    textAlign: 'center',
  },
  figcaption: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontStyle: 'italic',
  },
  container: {
    maxWidth: theme.breakpoints.width('sm'),
    marginTop: theme.spacing(4),
  },
  mainTitle: {
    marginBottom: theme.spacing(10),
  },
  section: {
    marginBottom: theme.spacing(8),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  sectionContent: {
    marginBottom: theme.spacing(6),
    '&:last-child': {
      marginBottom: 0,
    },
    '& ul': {
      paddingLeft: theme.spacing(10),
      margin: theme.spacing(2, 0),
      '& li:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    '& a': {
      color: theme.palette.link,
    },
  },
  textContent: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  accordionExpand: {
    fill: theme.palette.primary.main,
  },
  imageContent: {
    marginBottom: theme.spacing(6),
    '&:last-child': {
      marginBottom: 0,
    },
    '& img': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      margin: '0 auto',
    },
  },
  youtubeContent: {
    borderRadius: 0,
    boxShadow: 'none',
  },
  backToTopContainer: {
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.active,
    borderRadius: '50%',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    [theme.breakpoints.up('sm')]: {
      width: '3rem',
      height: '3rem',
    },
  },
  backToTopIcon: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
