import { Dialog, Typography, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './style';

const Modal = ({ open, handleClose, content }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} elevation={0}>
      <CloseIcon className={classes.close} onClick={handleClose} />

      <Typography variant="h2" color="primary">
        {content.title}
      </Typography>

      <Divider className={classes.divider} />

      <Typography
        className={classes.text}
        variant="body2"
        dangerouslySetInnerHTML={{ __html: content.text }}
      />
    </Dialog>
  );
};

export default Modal;
