import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Container, AppBar, Toolbar, Typography } from '@material-ui/core';

import { Lottie } from '@crello/react-lottie';
import animationData from './animation.json';

import routes from '../../router';

import MenuIcon from '../MenuIcon';
import Drawer from '../Drawer';

import useStyles from './style';

const Header = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    if (!drawerOpen) {
      setDrawerOpen(true);
    }
  };

  return (
    <AppBar className={classes.root} position="static">
      <Container maxWidth="lg" disableGutters>
        <Toolbar className={classes.toolbar}>
          {!matches && (
            <>
              <div className={classes.mobileMenuButton} onClick={handleDrawerOpen}>
                <MenuIcon />
              </div>

              <Drawer
                open={drawerOpen}
                setOpen={setDrawerOpen}
                title={['megújul', 'az oskola utca']}
                menu={routes.filter(route => route.menu && !route.demo)}
                back={{ path: '/', text: 'főoldal' }}
              />
            </>
          )}

          <Link className={classes.titleContainer} to="/">
            <div>
              <Lottie config={{ animationData: animationData, loop: true }} />
            </div>
          </Link>

          {matches &&
            routes
              .filter(route => route.menu && !route.demo)
              .map(({ path, name }) => (
                <NavLink className={classes.desktopMenuItem} key={name} to={path}>
                  <Typography variant="button" color="primary">
                    {name}
                  </Typography>
                </NavLink>
              ))}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
