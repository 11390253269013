import HomePage from '../containers/HomePage';
import TransportPage from '../containers/TransportPage';
import TrafficPage from '../containers/TrafficPage';
import LocalHistoryPage from '../containers/LocalHistoryPage';
import FaqPage from '../containers/FaqPage';

import ArticlePage from '../containers/ArticlePage';

const routes = [
  {
    path: '/virtualis-tura',
    name: 'virtuális túra',
    menu: true,
  },
  {
    path: '/kozlekedes',
    name: 'közlekedés',
    component: TransportPage,
    menu: true,
  },
  {
    path: '/forgalmi-tereles',
    name: 'forgalmi terelés',
    component: TrafficPage,
    menu: true,
  },
  {
    path: '/helytortenet',
    name: 'helytörténet',
    component: LocalHistoryPage,
    menu: true,
  },
  {
    path: '/gyik',
    name: 'gyik',
    component: FaqPage,
    menu: true,
  },
  {
    path: '/cikk/:id',
    component: ArticlePage,
  },
  {
    path: '/',
    name: 'főoldal',
    component: HomePage,
  },
];

export default routes;
