import { useContext, useLayoutEffect } from 'react';

import DataContext from '../../context';

import Article from '../../components/Article/Article';

const FaqPage = () => {
  const {
    portalData: { faq },
  } = useContext(DataContext);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Article article={faq} />;
};

export default FaqPage;
