import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 4),
    background: theme.palette.background.primary,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
  toolbar: {
    minHeight: 0,
    padding: theme.spacing(4, 0),
  },
  titleContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
    textDecoration: 'none',
    '-webkit-tap-highlight-color': 'transparent',
  },
  desktopMenuItem: {
    display: 'block',
    margin: theme.spacing(0, 4),
    padding: theme.spacing(3, 6),
    textAlign: 'center',
    textDecoration: 'none',
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover, &.active': {
      backgroundColor: theme.palette.background.active,
    },
    '&.active span': {
      fontWeight: 600,
    },
  },
  desktopSubMenuContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    background: theme.palette.background.default,
    zIndex: 1,
  },
  mobileMenuButton: {
    display: 'flex',
    marginRight: theme.spacing(6),
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      fill: theme.palette.background.active,
    },
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
}));

export default useStyles;
