import { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// import Button from '@material-ui/core/Button';
// import PlayArrow from '@material-ui/icons/PlayArrow';

import YouTube from 'react-youtube';

import useStyles from './style';

const MediaViewer = props => {
  const classes = useStyles(props);
  const { className, type, src } = props;

  const player = useRef();

  // const [coverImageLoaded, setCoverImageLoaded] = useState(false);
  // const [onReady, setOnReady] = useState(false);
  const [play, setPlay] = useState(false);

  // const handlePlay = () => {
  //   if (onReady) {
  //     player.current.internalPlayer.playVideo();
  //     setPlay(true);
  //   }
  // };

  return (
    <div className={[classes.container, className].filter(Boolean).join(' ')}>
      {/* TODO: cover image and video from props */}
      {type === 'video' ? (
        <>
          {/* onReady={() => setOnReady(true)} */}
          {/* opts={{ playerVars: { controls: 0 } }} */}
          <YouTube
            containerClassName={classes.videoContainer}
            ref={player}
            videoId={src}
            onPause={() => setPlay(false)}
          />
          <div className={`${classes.videoCoverContainer} ${play && classes.play}`}>
            {/* <img
              className={classes.image}
              alt={aspectRatio === '16:9' ? '16:9' : '21:9'}
              src={
                aspectRatio === '16:9'
                  ? 'https://via.placeholder.com/1600x900.png?text=16:9'
                  : 'https://via.placeholder.com/2100x900.png?text=21:9'
              }
              onLoad={() => setCoverImageLoaded(true)}
            /> */}
            {/* <img
              className={classes.image}
              alt={src}
              src={'https://via.placeholder.com/2100x900.png?text=21:9'}
              onLoad={() => setCoverImageLoaded(true)}
            />
            {coverImageLoaded && (
              <Button color="primary" className={classes.playButton} onClick={handlePlay}>
                <PlayArrow />
              </Button>
            )} */}
          </div>
        </>
      ) : (
        <img className={classes.image} alt={src} src={src} />
      )}
    </div>
  );
};

const types = ['image', 'video'];
const aspectRatios = ['16:9', '21:9'];

MediaViewer.propTypes = {
  type: PropTypes.oneOf(types),
  aspectRatioXs: PropTypes.oneOf(aspectRatios),
  aspectRatioSm: PropTypes.oneOf(aspectRatios),
};

MediaViewer.defaultProps = {
  type: 'image',
  aspectRatioXs: '16:9',
  aspectRatioSm: '21:9',
};

export default MediaViewer;
