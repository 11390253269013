import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 4),
    background: theme.palette.background.secondary,
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridColumnGap: theme.spacing(8),
    gridRowGap: theme.spacing(8),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'auto auto',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr max-content 1fr',
    },
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  client: {
    display: 'inline-flex',
  },
  clientLogo: {
    width: '4rem',
    marginRight: theme.spacing(8),
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  contributors: {
    display: 'inline-flex',
  },
  constructors: {
    marginRight: theme.spacing(8),
  },
  partners: {
    [theme.breakpoints.up('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
    [theme.breakpoints.up('lg')]: {
      gridColumnStart: 3,
      gridColumnEnd: 4,
    },
  },
  partnerLogos: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  middle: {
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 12),
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
    },
  },
  copyright: {
    textAlign: 'center',
  },
}));

export default useStyles;
