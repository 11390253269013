import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  close: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(4),
    width: '2rem',
    height: '2rem',
    color: theme.palette.text.default,
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  text: {
    '& a': {
      color: theme.palette.link,
    },
    '& img': {
      width: '100%',
      height: 'auto',
      marginTop: theme.spacing(4),
    },
  },
}));

export default useStyles;
