import { createMuiTheme } from '@material-ui/core/styles';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';

const theme = createMuiTheme({
  spacing: factor => `${0.25 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 924,
      lg: 1080,
    },
  },
  palette: {
    text: {
      default: '#202020',
    },
    link: '#00b3e3',
    primary: {
      main: '#464646',
    },
    secondary: {
      main: '#fff',
    },
    background: {
      default: '#fff',
      primary: '#f6f8f9',
      //secondary: '#a6adb4',
      secondary: '#9ca4ac',
      active: '#ffd13f',
    },
  },
  fontFamily: {
    primary: 'Bebas Neue',
    secondary: 'Source Sans Pro',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

theme.overrides.MuiCssBaseline = {
  '@global': {
    html: {
      fontSize: 16,
    },
    body: {
      margin: 0,
      color: theme.palette.text.default,
      fontFamily: [theme.fontFamily.primary, theme.fontFamily.secondary].join(','),
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1,
    },
  },
};

// Main title in drawer menu
theme.typography.h1 = {
  fontFamily: theme.fontFamily.primary,
  fontSize: '1.75rem',
  lineHeight: 1,
  fontWeight: 400,
};
// Article main title
theme.typography.h2 = {
  fontFamily: theme.fontFamily.primary,
  fontSize: '2.5rem',
  lineHeight: 1,
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.5rem',
  },
};
// Card title and article subtitle
theme.typography.h3 = {
  fontFamily: theme.fontFamily.primary,
  fontSize: '1.875rem',
  lineHeight: 1,
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
};

// Carousel modal text
theme.typography.body1 = {
  fontFamily: theme.fontFamily.secondary,
  fontSize: '1.25rem',
  lineHeight: 1.35,
  fontWeight: 400,
};

// Card and article text
theme.typography.body2 = {
  fontFamily: theme.fontFamily.secondary,
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.125rem',
  },
};

// Header and footer button
theme.typography.button = {
  fontFamily: theme.fontFamily.secondary,
  fontSize: '0.875rem',
  textTransform: 'uppercase',
  fontWeight: 400,
};

// Footer and image caption text
theme.typography.caption = {
  fontFamily: theme.fontFamily.secondary,
  fontSize: '0.75rem',
  fontWeight: 400,
};

theme.overrides.MuiButton = {
  root: {
    minHeight: '2rem',
    border: 0,
    borderRadius: 0,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    fontFamily: theme.fontFamily.primary,
    fontSize: '1.5rem',
    lineHeight: 1,
    fontWeight: '400',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
  text: {
    padding: theme.spacing(0, 9),
  },
  textPrimary: {
    backgroundColor: theme.palette.background.active,
    '&:hover': {
      backgroundColor: darken(theme.palette.background.active, 0.05),
      '@media (hover: none)': {
        backgroundColor: darken(theme.palette.background.active, 0.05),
      },
    },
  },
};

theme.overrides.MuiPaper = {
  root: {
    color: theme.palette.text.default,
    backgroundColor: fade(theme.palette.background.primary, 0.9),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
  rounded: {
    borderRadius: theme.spacing(4),
  },
};

theme.overrides.MuiDialog = {
  paper: {
    margin: theme.spacing(8),
    padding: theme.spacing(6, 8),
    paddingTop: theme.spacing(12),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.background.secondary}`,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 12),
    },
  },
  paperWidthSm: {
    maxWidth: '35rem',
  },
};

theme.overrides.MuiCard = {
  root: {
    backgroundColor: theme.palette.background.primary,
    borderRadius: theme.spacing(1),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
};

theme.overrides.MuiCardMedia = {
  root: {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
};

theme.overrides.MuiDrawer = {
  paper: {
    padding: theme.spacing(8, 12),
    backgroundColor: theme.palette.background.active,
  },
};

theme.overrides.MuiDivider = {
  root: {
    margin: theme.spacing(6, 0),
    backgroundColor: theme.palette.primary.main,
  },
};

theme.overrides.MuiAccordion = {
  root: {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
    '&:before': {
      backgroundColor: 'none',
    },
  },
};
theme.overrides.MuiAccordionSummary = {
  root: {
    minHeight: 'auto',
    padding: theme.spacing(2.5, 3.5),
    backgroundColor: theme.palette.background.active,
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
  expandIcon: {
    order: 1,
    alignSelf: 'flex-start',
    marginRight: theme.spacing(3),
    padding: 0,
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    order: 2,
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiTypography-body2': {
      fontWeight: 600,
    },
    '& > p': {
      lineHeight: 1.3,
    },
  },
};

theme.overrides.MuiAccordionDetails = {
  root: {
    display: 'block',
    padding: theme.spacing(4, 8),
    backgroundColor: theme.palette.background.primary,
    '& .MuiTypography-root:not(:last-child)': {
      //marginBottom: theme.spacing(4),
    },
  },
};

export default theme;
