import { useContext, useLayoutEffect } from 'react';

import DataContext from '../../context';

import Article from '../../components/Article/Article';

const TrafficPage = () => {
  const {
    portalData: { traffic },
  } = useContext(DataContext);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Article article={traffic} />;
};

export default TrafficPage;
