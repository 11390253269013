import { useContext } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import DataContext from '../../context';

import VirtualTour from '../../components/VirtualTour';
import Loading from '../../components/Loading';

const VirtualTourContainer = () => {
  let { path } = useRouteMatch();
  const { virtualTours } = useContext(DataContext);

  return virtualTours ? (
    <Switch>
      <Route path={`${path}/:sceneId`} component={VirtualTour} />
      <Route path={`${path}`}>
        <Redirect to={`${path}/${virtualTours[0].id}`} />
      </Route>
    </Switch>
  ) : (
    <Loading />
  );
};

export default VirtualTourContainer;
